import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { msalInstance } from './authConfig';
import { format, isSameDay } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import logo from './logo.png';
import GetAcknowledgments from './GetAcknowledgments';
import { loginRequest } from './authConfig'; // Import the updated config
import { jwtDecode } from 'jwt-decode';


const depots = [
  { label: 'Select depot', value: '' },
  { label: 'Ashford', value: 'Ashford' },
  { label: 'Colchester', value: 'Colchester' },
  { label: 'Exeter', value: 'Exeter' },
  { label: 'Leeds', value: 'Leeds' },
  { label: 'Leicester', value: 'Leicester' },
  { label: 'Newcastle', value: 'Newcastle' },
  { label: 'Swindon', value: 'Swindon' },
  { label: 'Warrington', value: 'Warrington' },
];

const messages = [
  'Waking up Field Tablets...',
  'Triangulating location data...',
  'Politely asking servers for data...',
  'Optimizing paperless protocols...',
  'Retrieving acknowledgments from the Matrix...',
  'Scanning for digital signatures...'
];

function AppContent() {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [acknowledgments, setAcknowledgments] = useState([]);
  const [filteredAcknowledgments, setFilteredAcknowledgments] = useState([]);
  const [filters, setFilters] = useState({ partitionKey: '', version: '', timestamp: null });
  const [sortOrder, setSortOrder] = useState({ key: '', ascending: true });
  const [hasAccess, setHasAccess] = useState(null); // Track if the user has access
  const [errorMessage, setErrorMessage] = useState(""); // Store any login error message
  const [loading, setLoading] = useState(false);  // New loading state


  // When new data is fetched, apply default sorting (newest first)
  useEffect(() => {
    if (acknowledgments.length > 0) {
      
      setFilteredAcknowledgments(acknowledgments);  // Set filtered to all initially
      //handleSort('formattedTimestamp');  // Sort by the newest first
    }
  }, [acknowledgments]);
  
  // Handle filtering
  const handleFilter = () => {
    const filtered = acknowledgments.filter((ack) => {
      return (
        (filters.partitionKey ? ack.partitionKey === filters.partitionKey : true) &&
        (filters.sBusinessVersion ? ack.sBusinessVersion.includes(filters.sBusinessVersion) : true) &&
        (filters.timestamp ? isSameDay(new Date(ack.timestamp), filters.timestamp) : true)
      );
    });
    //console.log("Filtered Acknowledgments:", filtered); 
    setFilteredAcknowledgments(filtered);
  };

  // Handle sorting
  const handleSort = (key) => {
    const isAscending = sortOrder.key === key ? !sortOrder.ascending : true;
    const sortedData = [...filteredAcknowledgments].sort((a, b) => {
      if (a[key] < b[key]) return isAscending ? -1 : 1;
      if (a[key] > b[key]) return isAscending ? 1 : -1;
      return 0;
    });
    setFilteredAcknowledgments(sortedData);
    setSortOrder({ key, ascending: isAscending });
  };


  useEffect(() => {
    handleFilter();  // Apply filters whenever the filters state changes
  }, [filters]);

  // Function to handle login
  const handleLogin = () => {
    instance.loginPopup(loginRequest)
      .then(response => {
        //console.log("Access Token:", response.accessToken);
        // Assuming you have a function `validateRoles` to check if the user has the right roles
        const hasValidRole = validateRoles(response.idToken);
  
        if (!hasValidRole) {
          setErrorMessage("You do not have the necessary roles to access this application.");
        } else {
          setErrorMessage(""); // Clear the error message if the user has the correct roles
          // Proceed with your post-login logic here
        }
      })
      .catch(e => {
        //console.error("Login error:", e);
        setErrorMessage("There was an error logging in. Please try again.");
      });
  };

  const validateRoles = (idToken) => {
    const decodedToken = jwtDecode(idToken);  
    //console.log(decodedToken);
      // Check if the user's roles match your app's required roles
    //Note nothing exists in this case, everyone should be given viewer, but it gives scope for more functionality to admins - perhaps a delete ability.
    if (decodedToken.roles && decodedToken.roles.includes("Viewer")) {
      //console.log("user has correct role");
      return true;  // User has the correct role
    }
    //console.log("user do not have correct role");
    return false;  // User does not have the correct role
  };
  

  // Handle logout
  const handleLogout = () => {
    instance.logoutPopup().catch(e => console.error("Logout error:", e));
  };

  useEffect(() => {
    if (accounts.length > 0) {
      //console.log("Logged in user:", accounts[0]);
    }
  }, [accounts]);


  const LoadingModal = () => {
    const [currentMessage, setCurrentMessage] = useState(messages[0]);
    const [messageIndex, setMessageIndex] = useState(0);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length); // Cycle through the messages
      }, 1000); // Change message every 2 seconds
  
      return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, []);
  
    useEffect(() => {
      setCurrentMessage(messages[messageIndex]);
    }, [messageIndex]);
  
    return (


<div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-75 z-50">
<div className="bg-white p-6 rounded-lg shadow-lg text-center items-center">
<div className="flex flex-col items-center">
<div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-green-500 mb-4"></div>
{/* Message */}
<p className="text-teal-800">{currentMessage}</p>
</div>        </div>
</div>

    );
  };
  

  return (
    <div className="min-h-screen flex flex-col items-center bg-white-100 p-6">
      {/* Logo Section */}
      <header className="w-full text-center mb-6 flex flex-col items-center">
        <img src={logo} alt="Logo" className="w-365 h-auto mb-4" />
        <h1 className="text-4xl font-bold mb-4 text-teal-800">RAMS Acceptance Sign Off</h1>
        
        {errorMessage && (
        <div className="text-red-500 font-bold mb-4">{errorMessage}</div>
      )}

      {accounts.length === 0 && (
        <button 
          onClick={handleLogin} 
          className="bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-blue-700">
          Log In with Microsoft 365
        </button>
      )}


      </header>


      {/* Display loading modal when loading is true */}
      {loading && <LoadingModal />}

      {/* Ensure MSAL is fully initialized and the user is authenticated before making any API calls */}
      {accounts.length > 0 && !errorMessage && isAuthenticated && inProgress === InteractionStatus.None && (
        <>
          {/* Call GetAcknowledgments to fetch data */}
          <GetAcknowledgments setAcknowledgments={setAcknowledgments} setFilteredAcknowledgments={setFilteredAcknowledgments} setLoading={setLoading}/>

          {/* Filters */}
          <div className="flex space-x-4 mb-6">
            <select
              className="border p-2 rounded"
              value={filters.partitionKey}
              onChange={(e) => setFilters({ ...filters, partitionKey: e.target.value })}
            >
              {depots.map((depot) => (
                <option key={depot.value} value={depot.value}>
                  {depot.label}
                </option>
              ))}
            </select>

            <input
              type="text"
              placeholder="Filter by Version"
              className="border p-2 rounded"
              value={filters.version}
              onChange={(e) => setFilters({ ...filters, version: e.target.value })}
            />

            <DatePicker
              selected={filters.timestamp}
              onChange={(date) => setFilters({ ...filters, timestamp: date })}
              dateFormat="dd/MM/yyyy"
              placeholderText="Filter by Date"
              className="border p-2 rounded"
            />
          </div>

          {/* Data Table */}
          <div className="w-full max-w-4xl">
            <table className="table-auto w-full bg-white shadow-md rounded-lg overflow-hidden">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th className="py-3 px-4 text-left cursor-pointer" onClick={() => handleSort('partitionKey')}>Depot</th>
                  <th className="py-3 px-4 text-left cursor-pointer" onClick={() => handleSort('name')}>Name</th>
                  <th className="py-3 px-4 text-left cursor-pointer" onClick={() => handleSort('sBusinessVersion')}>Version</th>
                  <th className="py-3 px-4 text-left cursor-pointer" onClick={() => handleSort('formattedTimestamp')}>Timestamp</th>
                  <th className="py-3 px-4 text-left">Signature</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm">
                {filteredAcknowledgments.map((ack) => (
                  <tr key={ack.rowKey} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-4">{ack.partitionKey}</td>
                    <td className="py-3 px-4 font-bold">{ack.name}</td>
                    <td className="py-3 px-4">{ack.sBusinessVersion}</td>
                    <td className="py-3 px-4">{ack.formattedTimestamp}</td>
                    <td className="py-3 px-4">
                      {ack.signature ? (
                        <img src={`${ack.signature}`} alt="Signature" className="w-32 h-16 object-contain border border-gray-400" />
                      ) : (
                        <span>No Signature</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

        {/* Show logout button if logged in */}
        {isAuthenticated && (
          <button
            onClick={handleLogout}
            className="bg-red-500 text-white px-6 py-2 rounded-full hover:bg-red-700 mt-4">
            Log Out
          </button>
        )}

      {/* Loading state while MSAL is initializing */}
      {inProgress !== InteractionStatus.None && <div>Loading authentication...</div>}
    </div>
  );
}

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <AppContent />
    </MsalProvider>
  );
}

export default App;
