import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "ecd465a1-12b2-465f-8f76-ea7e6b517f48",  // Replace with your app's client ID
    authority: "https://login.microsoftonline.com/317ce005-02fb-4f67-bfd2-f65e9f900f5e",  // Authority for multi-tenant
    //redirectUri: "http://localhost:3000",  // Local redirect URI
    redirectUri: "https://handbook.maxenergy.direct",  // Local redirect URI
    scopes: ['api://ecd465a1-12b2-465f-8f76-ea7e6b517f48/user_impersonation'],
    postLogoutRedirectUri: "https://handbook.maxenergy.direct",
    //postLogoutRedirectUri: "http://localhost:3000",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where the tokens are stored
    storeAuthStateInCookie: false, // Set to true if cookies are being used to store state
  },
};

export const loginRequest = {
  scopes: ['https://graph.microsoft.com/Directory.Read.All', 'User.Read']
};

export const msalInstance = new PublicClientApplication(msalConfig);
