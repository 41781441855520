import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { useEffect } from 'react';

const GetAcknowledgments = ({ setAcknowledgments, setFilteredAcknowledgments, setLoading  }) => {
  const { accounts, instance } = useMsal();

  useEffect(() => {
    if (accounts.length > 0) {
      console.log("len = " + accounts.length);
      const fetchAcknowledgments = async () => {
        try {
          setLoading(true);  // Set loading to true before starting the fetch process

          const accessTokenRequest = {
            scopes: ['api://ecd465a1-12b2-465f-8f76-ea7e6b517f48/user_impersonation'],
            account: accounts[0],
          };

          // Simulate a delay for demonstration purposes
          //console.log("Fetching acknowledgments for account:", accounts[0].username);  // Logging account info once

   
          await new Promise(resolve => setTimeout(resolve, 1500));  // 2 seconds delay


          // Acquire token silently
          const tokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
          const token = tokenResponse.accessToken;

          // Make API call with the token
          const response = await axios.get('https://maxhandbookadmin.azurewebsites.net/api/GetAcknowledgements?', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          const formattedData = response.data.map((ack) => ({
            ...ack,
            formattedTimestamp: new Date(ack.timestamp).toLocaleString('en-GB'), // Adjust for your format
          }));

          // Set both acknowledgments and filteredAcknowledgments to show all records initially
          setAcknowledgments(formattedData);
          setFilteredAcknowledgments(formattedData); // Set filtered to all initially
          
        } catch (error) {
          console.error('Error fetching acknowledgments:', error);
        }
        finally {
          setLoading(false);  // Once the fetch is done, stop loading
        }
      };

      fetchAcknowledgments();
    }
  }, [accounts, instance, setAcknowledgments, setFilteredAcknowledgments, setLoading]);

  return null; // This component doesn't need to render anything
};

export default GetAcknowledgments;

